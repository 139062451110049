<template>
  <ThemeProvider is-root v-bind="themeConfig" :apply-style="false">
    <stepin-view
      logo-src="@/assets/logo/pandalogo-wz2.png"
      :class="`${contentClass}`"
      :user="user"
      :navMode="navigation"
      :useTabs="useTabs"
      :themeList="themeList"
      :menuList="menuList"
      v-model:show-setting="showSetting"
      v-model:theme="theme"
      @themeSelect="configTheme"
    >
      <template #headerActions>
        <HeaderActions @showSetting="showSetting = true" />
      </template>
      <template #pageFooter>
        <PageFooter />
      </template>
      <template #themeEditorTab>
        <a-tab-pane tab="其它" key="other">
          <Setting />
        </a-tab-pane>
      </template>
    </stepin-view>
    <PageFooter />
  </ThemeProvider>
  <login-modal :unless="['/login']" />
</template>

<script lang="ts" setup>
  import { reactive, ref, watch, onMounted } from 'vue';
  import {useRouter} from 'vue-router';
  import {useAccountStore, useSettingStore, storeToRefs, useSysInfoStore} from '@/store';
  import mavatar from '@/assets/avatar.png';
  import { PageFooter, HeaderActions } from '@/components/layout';
  import Setting from './components/setting';
  import { LoginModal } from '@/pages/login';
  import { configTheme, themeList } from '@/theme';
  import { ThemeProvider } from 'stepin';
  import { computed } from 'vue';
  import { MenuConfig } from 'stepin/es/router-menu'
  import {message} from "ant-design-vue";
  import {getToken} from "@/store/local";


  const { logout, profile } = useAccountStore();
  const form =reactive({
    endtime: '已到期',
  })

  const sysStore = useSysInfoStore()
  sysStore.checkDevice()

  const checkIsMobile =()=> {
    if (sysStore.isMobile){
      message.info('请在电脑端使用',10)
    }
  }
  onMounted(checkIsMobile)

  // todo 当cookie为空时，获取个人信息
  if(getToken()){
    profile().then((response) => {
      const { username,avatar,endtime } = response;
      form.endtime=endtime
      user.name = username;
      if (endtime !== null){
        form.endtime='到期时间:'+endtime.slice(0,endtime.indexOf("T"))
      }
      if(avatar=="/0"){
        user.avatar = mavatar
      }else {
        user.avatar = avatar;
      }
    });
  }
  watch(() => form.endtime, () => {
    user.menuList = [
      // { title: '个人中心', key: 'personal', icon: 'UserOutlined', onClick: () => router.push('/profile') },
      { title: form.endtime, key: 'setting', icon: 'SettingOutlined', onClick: () => router.push('/pay') },
      { title: '购买会员', key: 'setting', icon: 'SettingOutlined', onClick: () => (showSetting.value = true) },
      { type: 'divider' },
      {
        title: '退出登录',
        key: 'logout',
        icon: 'LogoutOutlined',
        onClick: () => logout().then(() => router.push('/login')),
      },
    ]
  });

  const showSetting = ref(false);
  const router = useRouter();

  // useMenuStore().getMenuList();

  const { navigation, useTabs, theme, contentClass } = storeToRefs(useSettingStore());
  const themeConfig = computed(() => themeList.find((item) => item.key === theme.value)?.config ?? {});

  const user = reactive({
    name: 'admin',
    avatar: mavatar,
    menuList: [
      // { title: '个人中心', key: 'personal', icon: 'UserOutlined', onClick: () => router.push('/profile') },
      { title: form.endtime, key: 'setting', icon: 'SettingOutlined', onClick: () => router.push('/pay') },
      { title: '设置', key: 'setting', icon: 'SettingOutlined', onClick: () => (showSetting.value = true) },
      { type: 'divider' },
      {
        title: '退出登录',
        key: 'logout',
        icon: 'LogoutOutlined',
        onClick: () => logout().then(() => router.push('/login')),
      },
    ],
  });

  const menuList = ref<MenuConfig>([
    // { title: '工作台', path: '/workplace', meta: { icon: 'DashboardOutlined' } },
    { title: '写作', path: '/write', meta: { icon: 'FormOutlined' } },
    // { title: '表单',
    //   path: '/form',
    //   meta: { icon: 'FormOutlined' },
    //   children: [
    //     { title: '基础表单', path: '/form/basic' },
    //     { title: '高级表单', path: '/form/advance' },
    //   ]
    // },
    // { title: '计划',
    //   path: '/plan',
    //   meta: { icon: 'FormOutlined' },
    // },
    { title: '发布',
      path: '/publish',
      meta: { icon: 'DashboardOutlined' },
    },
  ])
</script>

<style lang="less">
  .stepin-view {
    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      border-radius: 4px;
      background-color: theme('colors.primary.500');
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: theme('colors.primary.400');

      &:hover {
        background-color: theme('colors.primary.500');
      }
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
      border-radius: 4px;
      background: theme('backgroundColor.layout');
    }
  }

  html {
    height: 100vh;
    overflow-y: hidden;
  }

  body {
    margin: 0;
    height: 100vh;
    overflow-y: hidden;
  }
  .stepin-img-checkbox {
    @apply transition-transform;
    &:hover {
      @apply scale-105 ~"-translate-y-[2px]";
    }
    img {
      @apply shadow-low rounded-md transition-transform;
    }
  }
</style>
