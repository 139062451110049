import { defineStore } from 'pinia';
import http from './http';
import { Response } from '@/types';
// import { useMenuStore } from './menu';
import { useAuthStore } from '@/plugins';
import {getInvite, getReffer, removeUserInfo, setUserInfo} from "@/store/local";
import useMyAuth from "@/plugins/auth/auth-store.js";

export interface Profile {
  data: Account;
  status: string;
  // account: Account;
  // permissions: string[];
  // role: string;
}
export interface Account {
  username: string;
  avatar: string;
  gender: number;
  signature: string;
  endtime: string;
  user_id: number;
  access: string;
}

export type TokenResult = {
  token: string;
  expires: number;
  status: string;
  username: string;
  user_id: number;
  type: number;
};
export const useAccountStore = defineStore('account', {
  state() {
    return {
      account: {} as Account,
      permissions: [] as string[],
      role: '',
      logged: true,
      uid: '',
    };
  },
  actions: {
    async login(username: string, password: string, reffer = "null", invite: string = "0") {
      return http
        .request<TokenResult, Response<TokenResult>>('/login', 'post_json', { username, password,reffer,invite })
        .then(async (response) => {
          if (response.status === "ok") {
            this.logged = true;
            this.uid=response.data.user_id
            http.setAuthorization(`JWT-${response.data.token}`, 1296000000);
            // await useMenuStore().getMenuList();
            return response.data;
          } else {
            return Promise.reject(response);
          }
        });
    },
    async plogin(mobile: string, smscode: string) {
      let reffer = getReffer()
      let invite= getInvite()
      return http
          .request<TokenResult, Response<TokenResult>>('/user/phonelogin/', 'post', { mobile, smscode, reffer, invite })
          .then(async (response) => {
            if (response.data.status === "ok") {
              this.logged = true;
              this.uid=response.data.user_id
              this.role=response.data.type
              const myAuth=useMyAuth();
              myAuth.setRole(this.role)
              const data=JSON.stringify({"uid":this.uid})
              setUserInfo(data)
              http.setAuthorization(`JWT-${response.data.token}`, 15);
              // await useMenuStore().getMenuList();
              return response.data;
            } else {
              return Promise.reject(response);
            }
          });
    },
    async logout() {
      return new Promise<boolean>((resolve) => {
        localStorage.removeItem('stepin-menu');
        http.removeAuthorization();
        removeUserInfo();
        this.logged = false;
        resolve(true);
      });
    },
    async profile() {
      return http.request<Account, Response<Profile>>('/user/info/', 'get').then((response) => {

        if (response.data.status === "ok") {
          const { setAuthorities } = useAuthStore();
          const { access,user_id } = response.data.data;
          this.permissions = access;
          this.role = access;
          this.uid= user_id;
          const data=JSON.stringify({"uid":user_id})
          const myAuth=useMyAuth();
          myAuth.setRole(this.role)
          setUserInfo(data)
          setAuthorities(this.permissions);
          return response.data.data;
        } else {
          return Promise.reject(response);
        }
      });
    },
    setLogged(logged: boolean) {
      this.logged = logged;
    },
  },
});
