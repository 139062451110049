import service from "@/utils/defhttp";

const toolsApi = {
    SMS: '/user/sms/code/',
    Invite: '/order/u/invite/',
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function getSms(data) {
    return service({
        url: toolsApi.SMS,
        method: 'post',
        data
    })
}

export function getInvite() {
    return service(
        {
            url: toolsApi.Invite,
            method: 'GET',
        },

    )
}