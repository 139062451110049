import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name:'myhome',
    meta: {
      title: '首页',
      view: 'blank',
      icon: 'CreditCardOutlined',
    },
    component: () => import('@/pages/home'),
  },
  {
    path: '/pay',
    name: 'pay',
    meta: {
      renderMenu: false,
    },
    component: () => import('@/pages/billing/index'),
  },
  {
    path: '/test',
    name: 'test',
    meta: {
      renderMenu: false,
    },
    component: () => import('@/pages/Test.vue'),
  },
  {
    path: '/write',
    name: 'write',
    meta: {
      renderMenu: false,
    },
    component: () => import('@/pages/write'),
  },
  {
    path: '/publish',
    name: 'publish',
    meta: {
      renderMenu: false,
    },
    component: () => import('@/pages/plan/publish.vue'),
  },
  {
    path: '/homedetail',
    name: 'homedetail',
    meta: {
      renderMenu: false,
    },
    component: () => import('@/pages/home/homedetail.vue'),
  },
  {
    path: '/edit',
    name: 'edit',
    meta: {
      renderMenu: false,
    },
    component: () => import('@/pages/plan/EditContent.vue'),
  },
  {
    path: '/invite',
    name: 'invite',
    meta: {
      renderMenu: false,
    },
    component: () => import('@/pages/invite/invite.vue'),
  },
  {
    path: '/plan',
    name: 'plan',
    meta: {
      renderMenu: false,
    },
    component: () => import('@/pages/plan/Plan.vue'),
  },
  {
    path: '/front',
    name: 'front',
    meta: {
      renderMenu: false,
    },
    component: () => import('@/components/layout/FrontView.vue'),
    children: [
      {
        path: '/login',
        name: 'login',
        meta: {
          icon: 'LoginOutlined',
          view: 'blank',
          target: '_blank',
          cacheable: false,
        },
        component: () => import('@/pages/login'),
      },
      // {
      //   path: '/',
      //   name: 'home',
      //   meta: {
      //     view: 'blank',
      //     icon: 'CreditCardOutlined',
      //   },
      //   component: () => import('@/pages/home'),
      // },
      {
        path: '/home',
        name: 'home',
        meta: {
          view: 'blank',
          icon: 'CreditCardOutlined',
        },
        component: () => import('@/pages/home'),
      },
      {
        path: '/homelist',
        name: 'homelist',
        meta: {
          view: 'blank',
          icon: 'CreditCardOutlined',
        },
        component: () => import('@/pages/home/homelist.vue'),
      }
    ],
  },
  {
    path: '/403',
    name: '403',
    props: true,
    meta: {
      renderMenu: false,
    },
    component: () => import('@/pages/Exp403.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    props: true,
    meta: {
      icon: 'CreditCardOutlined',
      renderMenu: false,
      cacheable: false,
      _is404Page: true,
    },
    component: () => import('@/pages/Exp404.vue'),
  },
];

export default routes;
