import Cookies from 'js-cookie'

const TokenKey = 'Authorization'

const UserInfo = 'userinfo'


export function getReffer():string {
    return Cookies.get("Referrer")
}


export function getInvite():string {
    return Cookies.get("ivt")
}

// token设置
export function getToken () {
    return Cookies.get(TokenKey)
}

export function setToken (token) {
    return Cookies.set(TokenKey, token, { expires: 7 })
}

export function removeToken () {
    return Cookies.remove(TokenKey)
}

// 用户信息设置
export function setUserInfo (msg) {
    return Cookies.set(UserInfo, msg)
}

export function getUserInfo () {
    let res = Cookies.get(UserInfo)
    return res ? JSON.parse(res) : 'none'
}

export function removeUserInfo () {
    console.log('删除')
    Cookies.remove(TokenKey)
    Cookies.remove(UserInfo)
}
