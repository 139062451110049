import createHttp from '@/utils/axiosHttp';
import NProgress from 'nprogress';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import {getToken} from "@/store/local";
import {message} from "ant-design-vue";

const service = axios.create({
    timeout: 10000,
    baseURL: 'api',
    withCredentials: true,
    xsrfCookieName: 'Authorization',
    xsrfHeaderName: 'Authorization',
});


// progress 进度条 -- 开启
service.interceptors.request.use((req: AxiosRequestConfig) => {
    if (!NProgress.isStarted()) {
        NProgress.start();
    }
    return req;
});

service.interceptors.request.use(
    config => {
        // do something before request is sent

        // if (store.getters.token) {
        //   // let each request carry token
        //   // ['X-Token'] is a custom headers key
        //   // please modify it according to the actual situation
        //   config.headers['Authorization'] = getToken()
        // }
        config.headers['Authorization'] = getToken()
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data

        // if the custom code is not 20000, it is judged as an error.
        if (res.status !== 'ok' && res.status !== 'true' && res.status !== 'success') {
            message.error(res.data || '网络错误',10)
            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            // todo 错误处理
            // if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
            //     // to re-login
            //     MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
            //         confirmButtonText: 'Re-Login',
            //         cancelButtonText: 'Cancel',
            //         type: 'warning'
            //     }).then(() => {
            //         store.dispatch('user/resetToken').then(() => {
            //             location.reload()
            //         })
            //     })
            // }
            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error) // for debug
        message.error(error.message,10);
        return Promise.reject(error)
    }
)

// progress 进度条 -- 关闭
service.interceptors.response.use(
    (rep) => {
        if (NProgress.isStarted()) {
            NProgress.done();
        }
        return rep;
    },
    (error) => {
        if (NProgress.isStarted()) {
            NProgress.done();
        }
        return error;
    }
);
export default service