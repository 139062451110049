import { defineStore } from 'pinia';

export const useMyAuth=defineStore('myauth',{
    state:()=>({
        role:0,
    }),
    actions:{
        setRole(ver){
            this.role=ver
        },
    }
})

export default useMyAuth;