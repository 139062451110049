<template>
  <ThemeProvider :color="{ middle: { 'bg-base': '#fff' }, primary: { DEFAULT: '#1896ff' } }">
    <div class="login-box rounded-sm">

      <a-form
        :model="form"
        :wrapperCol="{ span: 24 }"
        @finish="mlogin"
        class="login-form w-[400px] p-lg xl:w-[440px] xl:p-xl h-fit text-text"
      >
        <img :src="logoPic" alt="logo" style="width: 100%;margin-bottom:5%">
        <a-form-item  name="手机号">
          <a-input
            class="h-[40px] w-full"
            v-model:value="form.phone"
            placeholder="请输入手机号"
          />
        </a-form-item>
        <a-form-item  name="验证码">
          <a-input
              class="h-[40px] w-full"
              style="width: 70%"
              v-model:value="form.smscode"
              :maxLength="6"
              placeholder="请输入验证码"
          />
          <a-button
              class="h-[40px] w-full"
              :disabled="getCodeBtnDisable"
              @click="getCode()"
              style='width: 30%'
          >
            {{ codeBtnWord }}
          </a-button>
        </a-form-item>
        <a-space direction="vertical">
          <a-button htmlType="submit" class="h-[40px] w-full" type="primary" :loading="loading"> 登录/注册 </a-button>
<!--          <a-alert message="首次登录送30天会员" type="warning" />-->
          <div class="terms">
            登录即代表您同意我们的
            <span class="font-bold">用户条款 </span>、<span class="font-bold">注册条款 </span>、<span class="font-bold"> 数据使用协议 </span>、以及
            <span class="font-bold">Cookie使用协议</span>。
          </div>
        </a-space>
      </a-form>
    </div>
  </ThemeProvider>
</template>
<script lang="ts" setup>
  import { reactive, ref, computed } from 'vue';
  import { useAccountStore } from '@/store';
  // import { ThemeProvider } from 'stepin';
  import {getSms} from "@/api/tools";
  import {message} from "ant-design-vue";
  import logoPic from "@/assets/logo/pandlogo.png";

  export interface LoginFormProps {
    phone: string;
    smscode: string;
  }
  const loading = ref(false);
  const codeBtnWord = ref("获取验证码");
  const waitTime = ref(61);
  const form = reactive({
    phone: undefined,
    smscode: undefined,
  });

  const phoneNumberStyle = computed(() => {
    let reg = /^1[3456789]\d{9}$/;
    if (!reg.test(form.phone)) {
      return false;
    }
    return true;
  });

  const getCodeBtnDisable = computed(() => {
    if (waitTime.value === 61) {
      if (form.phone && phoneNumberStyle.value) {
        return false;
      }
      return true;
    }
    return true;
  });

  async function getCode() {
    const data = {"mobile":form.phone,"tpl":"login"}
    const res = await getSms(data)
    if (String(res.status)=="ok"){
      message.success("获取验证码成功",5)
      waitTime.value--;
      codeBtnWord.value = `${waitTime.value}s 后获取`;
      let timer = setInterval(() => {
        if (waitTime.value > 1) {
          waitTime.value--;
          codeBtnWord.value = `${waitTime.value}s 后获取`;
        } else {
          clearInterval(timer);
          codeBtnWord.value = "获取验证码";
          waitTime.value = 61;
        }
      }, 1000);
    }
    else {
      message.error("网络错误",10)
    }
  }

  const emit = defineEmits<{
    (e: 'success', fields: LoginFormProps): void;
    (e: 'failure', reason: string, fields: LoginFormProps): void;
  }>();

  const accountStore = useAccountStore();
  function mlogin(params: LoginFormProps) {
    loading.value = true;
    accountStore
      .plogin(form.phone, form.smscode)
      .then((res) => {
        console.log('结果'+res)
        emit('success', params);
      })
      .catch((e) => {
        message.error("验证码错误",10)
        emit('failure', e.message, params);
      })
      .finally(() => (loading.value = false));
  }
</script>
